<template>

  <div class="report_header">
    <div class="report_info">
      <div class="report_name">

        <h1>
          {{ props.data.name }}                
          <i data-popup="" data-content="Your Diary is dope and it's up for Diary of the Month!" class="smile icon score_diary"></i>          
        </h1>
      </div>
      <div class="report_statistic">
  
        <div class="user" v-if="props.data.item_user">
          <NuxtLink class="avatar" :to="props.data.item_user.link">
            <img :src="props.data.item_user.avatar_little" srcset="" data-srcset="" height="18" width="18" data-ratio="18-18" :alt="props.data.item_user.name">
          </NuxtLink>
          <div :class="'user_range hidden u_range_' + props.data.item_user.status"></div>
          <NuxtLink class="name" :to="props.data.item_user.link">
            {{ props.data.item_user.name }}            
          </NuxtLink>
        </div>
        
        <!-- <div v-if="props.data.approved" class="row_stat approved">
          <i class="icon-success-round"></i> 
          {{ $t('diary_info_approved') }} 
          <span class="list">{{ props.data.approved.join(',') }}</span>
        </div> -->

        <div class="row_stat" v-if="props.data.cnt_followers">
          <i class="icon-users" title="Diary info followers"></i> 
          {{ props.data.cnt_followers }}
        </div>
 
 
        <div class="row_stat update">
          <i class="icon-refresh" title="Diary info updated"></i>
          {{ $dayjs(props.data.update_date).fromNow() }}    
        </div>

      </div>


    </div>

    <div class="report_btn">        

      <DiaryViewActionsEdit
        :data="props.data"
        :diary="props.data.id"
        :diaryhidden="props.data.is_hidden ? true : false"
        :owner="props.data.item_user.id"
        :isfollow="props.data.addon?.follow"
        :followers="props.data.cnt_followers"
        @follow="$emit('follow')"
        @unfollow="$emit('unfollow')"
        @removediary="$emit('removediary')"
        @unpublishdiary="$emit('unpublishdiary')"
        @publishdiary="$emit('publishdiary')"
        />
 
      
    </div>

  </div>
  
</template>

<script setup>

const props = defineProps({
  data: {
    type: Object,
    required: true
  }, 
});
  

</script>

<style scoped>
 
 


.report_header{
   display: flex;
}

.report_header .report_info{
    flex-grow: 1;
}
.report_header .report_info .report_name{}
.report_header .report_info .report_name h1{
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 0;
}
.report_header .report_info .report_name .rip_diary{
  margin-left: 10px;
}
.report_header .report_info .report_name .score_diary{
  display: inline-block;
  border-radius: 43px;
  color: var(--gd-primary-back-color);
  font-size: 1.5rem;
  height: 23px;
  text-align: center;
  width: 23px;
  line-height: 1.7rem;
  font-weight: 800;
}
.report_header .report_info .report_statistic{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  gap: 0.3rem 0.7rem;
}
.report_header .report_info .report_statistic .user{
    display: flex;
    align-items: center;
}
.report_header .report_info .report_statistic .user .avatar{
    display: inline-block;
    margin-right: 7px;
    height: 18px;
    aspect-ratio: 1/1;
}
.report_header .report_info .report_statistic .user .avatar img{
    border-radius: 50px;
    max-height: 18px;
}
.report_header .report_info .report_statistic .user .user_range{}
.report_header .report_info .report_statistic .user .name{
    display: inline-block;
    color: var(--gd-text-gray-color);
    font-weight: bold;
}
.report_header .report_info .report_statistic .row_stat{
    white-space: nowrap;
}
.report_header .report_info .report_statistic .row_stat .icon-leaf-like{
    font-size: 1.4rem;
    vertical-align: sub;
}
.report_header .report_info .report_statistic .row_stat .icon{
    font-size: 1.1rem;
}

.report_header .report_btn{
    text-align: right;
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    align-content: flex-end;
    flex-direction: row;
    justify-content: flex-end;
}
.report_header .report_info .report_statistic .row_stat.approved{
    white-space: nowrap;
}
.report_header .report_info .report_statistic .row_stat.approved .list{
    font-weight: bold;
    color: #484848;
}

</style>
