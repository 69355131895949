<template>



    <UiModal
      v-if="active"
      :title="'New week type'"   
      :close-button-name="close"
      :is-transparent="true"
      :width="'70%'"
      :max-width="'400px'"
      @close="closeModal()"         
      >        
        
        <UiButton
          tag="NuxtLink"       
          :name="$t($constants.typeFaza[-1])"    
          type="ger float"      
          @click.native="createGerm()"      
          :to="'/diaries/' + data.link + '/week/edit/g' + $rand.str(4)"
          />
        

        <UiButton
          tag="NuxtLink"             
          :name="$t($constants.typeFaza[0])"    
          type="veg float"      
          @click.native="createVeg()"      
          :to="'/diaries/' + data.link + '/week/edit/v' + $rand.str(4)"
          />
      

        <UiButton
          tag="NuxtLink"             
          :name="$t($constants.typeFaza[1])"    
          type="flo float"      
          @click.native="createFlo()"      
          :to="'/diaries/' + data.link + '/week/edit/f' + $rand.str(4)"
          />

      
        <!-- <NuxtLink :to="'/diaries/' + data.link + '/week/edit/' + $rand.str(4) + 'g'" class="blue btn" @click.native="createGerm()">
          Germination
        </NuxtLink> -->
      
        
        <!-- <NuxtLink :to="'/diaries/' + data.link + '/week/edit/' + $rand.str(4) + 'v'" class="green btn" @click.native="createVeg()">
          Vegetation      
        </NuxtLink> -->
        
        <!-- <NuxtLink :to="'/diaries/' + data.link + '/week/edit/' + $rand.str(4) + 'f'" class="orange btn" @click.native="createFlo()">
          Flowering      
        </NuxtLink> -->
         
        <template
          v-for="(seed) in harvest_seeds"          
          >
          <UiButton
            tag="NuxtLink"             
            :name="$t($constants.typeFaza[2]) + ' ' + seed.item_brand.name + ' - ' + seed.name"    
            type="har float"      
            @click.native="createHar()"      
            :to="'/diaries/' + data.link + '/week/edit/h' + seed.ref_id"
            />
              
          <!-- <NuxtLink :to="'/diaries/' + data.link + '/week/edit/' + $rand.str(4) + 'h'" class="red btn" @click.native="createHar()">
            Harvest  {{ seed.item_brand.name }} - {{ seed.name }}
          </NuxtLink> -->
           
        </template>
      
      </UiModal>

  
</template>

<script>
   

export default {   
  components: { 
    
  },
  props: [
    'data', 
    'active', 
  ],
  data () {
    return {          
    }
  }, 
  created() {     
  },
  computed: { 
    harvest_seeds(){            
      var harvested_id = [];
      if(this.data.items_week){ 
        for(var w of this.data.items_week){
          if(w.faza == 2)
            harvested_id.push(w.item_harvest.item_product.id)
        }
      }
      var non_harvested = [];
      for(var s of this.data.items_seed){
        if(harvested_id.indexOf(s.id) < 0)
          non_harvested.push(s)
      }
      return non_harvested;
    }
  },
  methods: { 
    closeModal(){
      this.$emit('close');
    },
    createGerm(){
      // localStorage.name = 'hello';
      console.log('create germ');
      this.closeModal();
    },
    createVeg(){
      console.log('create veg');
      this.closeModal();
    },
    createFlo(){
      console.log('create flo');
      this.closeModal();
    },
    createHar(){
      console.log('create har');
      this.closeModal();
    },
  }
}
</script>

<style scoped>
 
  

</style>
