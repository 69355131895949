<template>

  <div class=" report_days_sticky">
    <div class="report_days ui sticky  sticky_days">

      <div class="divider divider_block photo_post_header divider_block_days">
        {{ $t('diary_info_diary_weeks') }}    
      </div>

      <HorizontalScroll
        :align="'center'"
        >

        <div class="day_items">                              

            <div 
              v-for="week in weeks_computed"    
              :class="'day_item day_faza_' + week.faza + '  post_day_' + week.id + (active == week.id ? ' active ' : '')" 
              :data-faza="week.faza" 
              @click.native="setActive(week.data)"                                                         
              >
              <div class="name">
                <i class="icon-leaf-like is_liked hidden"></i>
                <i class="icon-play" v-if="week.is_video"></i>
                {{ $constants.typeFazaShort[week.faza] ? $t($constants.typeFazaShort[week.faza]) : '' }}                  
              </div>
              <div class="num">
                {{ week.days }}
              </div>
              <div class="week">
                {{ week.hint }}                    
              </div>
            </div>

          

        
                    
          <div                 
            v-if="useAuth().isOwner(owner)"
            :class="'day_item day_faza_new  post_day_add post_day_plus'"      
            @click="toggleChooseFaza()"                                                                 
            >
            <div class="name">                  
              ADD
            </div>
            <div class="num">
              <i class="icon-plus"></i>
            </div> 
          </div>        
                    

        </div> 

      </HorizontalScroll>      
         

    </div>


  </div>

  
  
</template>

<script>
 


export default {   
  
  props: {
    weeks: {
      type: Array, 
      required: true,
    },
    diary: {
      type: Number,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
    owner: {
      type: Number,
      required: false,
    },
    active: {
      type: Number,
      required: false,
    },
    defweek: {
      type: Number,
      required: false,
    },
    weekEmptyLink: {
      type: Number,
      required: false,
    },
  },
  data () {
    return {        
      active_id: this.active,        
      active_choose_faza: false,  
      // defweek: this.defweek,  
    }
  },
  // watch: {    
  //   default_id: function(newVal, oldVal) {       
  //     this.default_id = newVal;
  //   },  
  //   active_week: function(newVal, oldVal) {       
  //     this.active_week = newVal;
  //   },  
  // }, 
  created() {    
    this.active_into = this.active;
    // this.query = this.$route.query.q;   
    
    // console.log('last_week.id');
    // console.log(this.active_week);
  },
  computed: { 
    weeks_computed(){

      var ret = [];
      // var last_days = 1;
      
      // console.log('this.weeks');
      // console.log(this.weeks);
      // // this.weeks = [];

      for(var item_week of this.weeks){        

        // if(last_days+1 < item_week.days){
        //   var empty_weeks = this.getEmptyWeeks(last_days, item_week.days, item_week.faza);
        //   console.log(empty_weeks);
        //   console.log('empty_weeks');
        //   weeks = [...weeks, ...empty_weeks];
        // }

        let seed_name = '';
        
        if(item_week.faza == 2)
          seed_name = item_week.item_harvest.item_product && item_week.item_harvest.item_product.name ? item_week.item_harvest.item_product.name.substr(0,3) : '';
         
        ret.push({
          id: item_week.id,
          faza: item_week.faza,
          days: item_week.faza == -1 ? 'G' : (item_week.faza == 2 ? seed_name : item_week.days),
          hint: item_week.faza == 2 ? item_week.item_harvest.item_product.item_brand.name : this.$t('create_week_view_number_plural'),
          is_video: item_week.is_video,
          is_empty: false,
          data: item_week
        });
        // last_days = item_week.days;
      }

      

      return ret;
    }
  },
  methods: {

    // getEmptyWeeks(from, to, faza){    
    //   var weeks = [];   
    //   for (const x of Array(to-from-1).keys()) {
    //     console.log(x);
    //     console.log('x');
    //     weeks.push({
    //       id: 'new',
    //       faza: faza,
    //       days: x+1+from,
    //       hint: this.$t('create_week_view_number_plural'),
    //       is_video: false,
    //       is_empty: true,
    //       data: {}
    //     })
    //   }
    //   return weeks;
    // },
    toggleChooseFaza(){
      // this.active_choose_faza = !this.active_choose_faza;
      this.$emit('create');
    },
    setActive(week){
      // alert();
      // console.log('week.id');
      // console.log(this.diary);
      // console.log('default_id');
      // console.log(this.def);
      // console.log(week.id);
      this.active_id = week.id;
      // this.active_week = week;
      this.$emit('choose', week);
      
      // this.$ga.loadWeek(this.diary,week.id);
    }
                                                  
    // console.log('this.active_week');
    // console.log(this.active_week);
  }
}
</script>

<style scoped>
 
 

.report_days{ 

} 
.report_days_sticky{
   margin-bottom: 20px;
}
.report_days.fixed.top .day_items{
    margin-top: 10px;
    height: 90px;
}
.report_days .day_items{
    text-align: center;
    width: 100%;
    height: 74px;
    display: flex;
    flex-wrap: nowrap;
    color: var(--gd-diary-week-text-color);
}


.report_days .day_item .is_liked{
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 7px;
    color: var(--gd-diary-week-faza-color);
    display: inline-block;
    font-size: 0.75rem;
    top: -6px;
}
.report_days .day_item .is_update{
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 7px;
    width: 8px;
    height: 8px;
    background-color: #FF3E3E;
    display: inline-block;
    font-size: 0.56rem;
    margin-top: 5px;
    color: var(--gd-diary-week-faza-color);
    /* padding: 1px 5px; */
    position: absolute;
    top: -6px;
    border: 1px var(--gd-diary-week-faza-color) solid;
    margin-left: 21px;
}
.report_days .day_item{
    display: flex;
    flex-direction: column;

    opacity: 0.5;
    color: var(--gd-diary-week-text-color);
    position: relative;
    margin: 0 2px;
    border-radius: 5px;
}
.report_days .day_item .name{
    text-align: center;
    color: var(--gd-diary-week-faza-color);
    font-size: 0.8rem;
    padding: 1px;
    -webkit-transition: top 1s ease-out 0.5s;
    -moz-transition: top 1s ease-out 0.5s;
    -o-transition: top 1s ease-out 0.5s;
    transition: top 1s ease-out 0.5s;
    width: 54px;
    overflow: hidden;
    border-radius: 3px 3px 0 0;
}
.report_days .day_item .week{

    text-align: center;
    font-size: 0.75em;
    margin-top: -10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 45px;
    /* display: inline-block; */
    margin-left: 10%;
}
.report_days .day_item .num{
  text-align: center;
    text-transform: capitalize;
    font-size: 1.6em;
    margin-top: 0px;
    background-color: transparent;
    width: 54px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px; 
    -webkit-transition: top 1s ease-out 0.5s;
    -moz-transition: top 1s ease-out 0.5s;
    -o-transition: top 1s ease-out 0.5s;
    transition: top 1s ease-out 0.5s;
}
.report_days .day_item.post_day_plus .name{
  color: black;
}
.report_days .day_item.post_day_plus .num i{
  font-size: 0.85rem;
}
.report_days .divider_block_days{
  margin-top: 0px;
}
.report_days .day_item .stat{
    margin-top: -6px;
    width: 100%;
    height: 5px;
    text-align: center;
}

.report_days .day_item.harvest_day{

}

.report_days .day_item .stat .stat_label{
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 5px;
    width: 6px;
    height: 2px;
    background-color: white;
    display: inline-block;
}

.report_days .day_item .stat .stat_label.comment{
    background-color: #419EDA;
}
.report_days .day_item .stat .stat_label.like{
    background-color: #FF3E3E;
}
.report_days .day_item .stat .stat_label.photo{
    background-color: #00B561;
}

.report_days .day_item.active .num{
  background-color: var(--gd-diary-week-num-back-color);
}

.report_days .day_item:hover .num{
    /* background-color: rgba(255, 255, 255, 0.45); */
}
.report_days .day_item.active,
.report_days .day_item:hover{
    opacity: 1;
}
.report_days .post_day_plus{
  background-color: #e6e6e6;
  border-radius: 3px;
}

.report_days .day_shadow_left .shadow_inner,
.report_days .day_shadow_right .shadow_inner{
    background-color: transparent;
    padding: 9px 10px 13px 13px;
    border-radius: 30px;
    height: 50px;
    width: 50px;
    cursor: pointer;
}

.report_days .day_shadow_left:hover .shadow_inner,
.report_days .day_shadow_right:hover .shadow_inner{
  background-color: white;
}
.report_days .day_shadow_left{
    position: absolute;
    left: 0;
    z-index: 10;
    display: none;
    font-size: 1.7em;
    padding: 9px 0px 12px 10px;
    background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 72%, rgba(255,255,255,0) 90%);
    background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 72%,rgba(255,255,255,0) 90%);
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 72%,rgba(255,255,255,0) 90%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#00e2e2e2',GradientType=1 );

}
.report_days .day_shadow_right{
    position: absolute;
    right: 0;
    z-index: 10;
    display: none;
    font-size: 1.7em;
    padding: 9px 0px 12px 10px;
    background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 28%, rgba(255,255,255,1) 90%);
    background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 28%,rgba(255,255,255,1) 90%);
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 28%,rgba(255,255,255,1) 90%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e2e2e2', endColorstr='#e2e2e2',GradientType=1 );

}


.report_days .day_item.day_faza_-2{
    border: 1px #cccccc solid;
}
.report_days .day_item.day_faza_-1{
    border: 1px var(--gd-faza-ger) solid;
}
.report_days .day_item.day_faza_0{
    border: 1px var(--gd-faza-veg) solid;
}
.report_days .day_item.day_faza_1{
    border: 1px var(--gd-faza-flo) solid;
}
.report_days .day_item.day_faza_2{
    border: 1px var(--gd-faza-har) solid;
}
.report_days .day_item.day_faza_-2 .name{
    background-color: #cccccc;
}
.report_days .day_item.day_faza_-1 .name{
    background-color: var(--gd-faza-ger);
}
.report_days .day_item.day_faza_0 .name{
    background-color: var(--gd-faza-veg);
}
.report_days .day_item.day_faza_1 .name{
    background-color: var(--gd-faza-flo);
}
.report_days .day_item.day_faza_2 .name{
    background-color: var(--gd-faza-har);
}
.report_days .day_item.active .name{
  color: white;
  border-radius: 4px 4px 0px 0px;
}

.report_days .day_item .name .icon-leaf-like{
    font-size: 0.6rem;
    margin: 0px;
    vertical-align: middle;
    margin-top: -3px;
}
.report_days .day_item .name .icon-play{
    font-size: 0.5rem;
    margin: 0px;
    vertical-align: middle;    
}
.report_days .day_item:hover .name{
    color: white;
    border-radius: 4px 4px 0px 0px;
}

.report_days .day_item.day_faza_new:hover .name{
    color: #9A9A9A;
}



</style>
