<template>
  <div ref="conditions" class="report_items report_seeds" :class="{'active': showMore}">
    <div v-if="showButton" class="btn_less" @click="toggle">
      <i class="icon-caret-down down"/>
      <i class="icon-caret-up up"/>
    </div>

    <template v-if="itemsEquip">
      <template v-for="item in itemsEquip" :key="item.ref_id">
        <template v-if="item.props?.faza !== undefined">
          <!-- Lamp Veg or Flo | fl -->
          <DiaryViewConditionsItem
            v-if="item.props?.type"
            :logo="item.item_brand.logo_s || mapperSetupDiaryIcon[item.props?.type]"
            :name="$constants.lampTypes[item.props.type] + '/' + item.props?.power + 'W'"
            :hint="item.name || item.sec_name"
            :count="item.cnt"
            :data-props="item.props"
            :faza="item.props?.faza"
          />

          <!-- Lamp Veg or Flo | custom -->
          <DiaryViewConditionsItem
            v-else
            :logo="item.item_brand.logo_s"
            :logo_webp="item.item_brand.logo_s"
            :logo_link="item.item_brand.link"
            :count="item.cnt"
            :name="item.name ? item.name : ($constants.lampTypes[item.props.type] + '/' + item.props?.power + 'W')"
            :name_link="item.link"
            :hint="item.item_brand.name"
            :hint_link="item.item_brand.link"
            :data-props="item.props"
            :faza="item.props?.faza"
          />
        </template>

        <DiaryViewConditionsItem
          v-else
          :logo="item.item_brand.logo_s"
          :logo_webp="item.item_brand.logo_s"
          :logo_link="item.link"
          :name="item.name || item.sec_name"
          :name_link="item.link"
          :hint="item.item_brand.name"
          :hint_link="item.item_brand.link"
        />
      </template>
    </template>

    <!-- TODO | remove after debug, cause this already in items_equip  -->
    <template v-if="data.items_nutrient">
      <template v-for="item in data.items_nutrient" :key="item.name">
        <DiaryViewConditionsItem
          :logo="item.logo_s"
          :logo_webp="item.logo_s"
          :logo_link="item.link"
          :name="item.name"
          :name_link="item.link"
          :hint="$t('diary_info_nutrients')"
          :hint_link="item.link"
        />
      </template>
    </template>

    <template v-if="data.items_tent">
      <template v-for="item in data.items_tent" :key="item.name">
        <DiaryViewConditionsItem
          v-if="item.item_brand.id < 1"
          logo="/images/setup_diary/setup_diary_icons-18.svg"
          :name="item.name"
          :hint="$t('diary_info_custom_tent_name')"
        />

        <DiaryViewConditionsItem
          v-else
          :logo="item.item_brand.logo_s"
          :logo_webp="item.item_brand.logo_s"
          :logo_link="item.item_brand.link"
          :name="item.name"
          :name_link="item.link"
          :hint="item.item_brand.name"
          :hint_link="item.item_brand.link"
        />
      </template>
    </template>

    <template v-for="award in data.items_award" :key="award.name">
      <DiaryViewAwardItem
        :icon="'/images/trophy/' + award.icon + '.svg'"
        :name="award.name"
        :link="award.link"
        :place="award.place"
      />
    </template>

    <DiaryViewConditionsItem
      v-if="data.type_room == 1"
      :icon="'ic ic-room-1'"
      :name="$t('universal_type_room_indoor')"
      :hint="$t('universal_room_type_title')"
      @click="openWiki('room', 'indoor')"
    />

    <DiaryViewConditionsItem
      v-if="data.type_room == 2"
      :icon="'ic ic-room-2'"
      :name="$t('universal_type_room_outdoor')"
      :hint="$t('universal_room_type_title')"
      @click="openWiki('room', 'outdoor')"
    />

    <template v-for="method in props.data.items_method" :key="method.id">
      <DiaryViewConditionsItem
        v-if="method.id >= 0"
        :icon="'ic ic-method-' + method.id"
        :name="$constants.typeMethodShort[method.id] ? $t($constants.typeMethodShort[method.id]) : ''"
        :hint="$t('create_week_view_number_plural') + ' ' + weeksCroped(method?.weeks) "
        @click="openWiki('method', $constants.typeMethodCode[method.id])"
      />
    </template>

    <template v-for="medium in mediums" :key="medium.id">
      <DiaryViewConditionsItem
        v-if="medium.id >= 0"
        :icon="'ic ic-medium-' + medium.id"
        :name="medium.id == 7 && medium.name ? medium.name : $t($constants.medium[medium.id])"
        :hint="$t('diary_info_grow_medium')"
        :percent="medium.value"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import {ECategorySort} from "~/types/Enums.ts"

const { t } = useI18n();
import debounce from 'lodash/debounce';

const mapperSetupDiaryIcon = {
  1: "/images/setup_diary/setup_diary_icons-07.svg",
  2: "/images/setup_diary/setup_diary_icons-08.svg",
  3: "/images/setup_diary/setup_diary_icons-06.svg",
}

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
});
const showMore = ref(false);
const conditions = ref(null);
const showButton = ref(false);
 

const itemsEquip = computed(() => {
  if (!props.data.items_equip.length) {
    return []
  }

  let items = JSON.parse(JSON.stringify(props.data.items_equip))

  items.sort((a, b) => {
    return ECategorySort.indexOf(a.category) - ECategorySort.indexOf(b.category);
  })

  const groupedByIdAndBrand = items.reduce((acc, item) => {
    // Create a unique key for grouping
    const key = `${item.id}_${item.item_brand.id}`

    // If there is already a key in the battery, increase the counter
    if (acc[key]) {
      acc[key].cnt += 1;
    } else {
      // Otherwise, we create a new key with an initial counter of 1
      item.cnt = 1;
      acc[key] = item;
    }
    return acc;
  }, {});

  // Convert the object back to an array
  return Object.values(groupedByIdAndBrand)
})

const mediums = computed(() => {
  return props.data.items_medium
})

const weeksCroped = (arr) => {
  // method.weeks?.join(', ')

  if(!arr.length){
    return t('diary_info_technique');
  }

  if(!arr[0]){
    return t('diary_info_technique');
  }

  arr = arr.filter((item) => {
    return item
  });

  arr = arr.map((item) => {
    return parseInt(item);
  });

  arr = arr.sort((a, b) => a - b);

  let result = [];
  let start = arr[0];

  for (let i = 1; i <= arr.length; i++) {
    // Check if elements not goes one by one, or end of array
    if (arr[i] !== arr[i - 1] + 1 || i === arr.length) {
      // If current element - is not the beginning of the subsequence, add range
      if (start !== arr[i - 1]) {
        result.push(`${start}-${arr[i - 1]}`);
      } else {
        result.push(`${start}`);
      }

      // Start new subsequence
      start = arr[i];
    }
  }

  return result.join(', ');
};

const checkOverflow = debounce((event) => {
  if (conditions.value) {
    let totalWidth = 0;
    const children = conditions.value.querySelectorAll('.item');

    children.forEach((child) => {
      totalWidth += child.offsetWidth + 10;
    });

    showButton.value = totalWidth > conditions.value.offsetWidth - 30;
  }
}, 300);

const toggle = function() {
  showMore.value = !showMore.value;
}

const openWiki = (type, name) => {
  useWikiModal().open(type, name);
}

onMounted(() => {
  checkOverflow();
  window.addEventListener('resize', checkOverflow);
});

onUpdated(checkOverflow);

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkOverflow);
})
</script>

<style scoped>



.report_items{
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: 50px;
  align-items: flex-start;
  position: relative;
  padding-right: 30px;
  margin-top: 10px;
  margin-bottom: 15px;
  gap: 10px;
}
.report_items.active{
  height: auto;
}
.report_items .item.condition_item{
  cursor: help;
}
.report_items .item{
  display: flex;
  position: relative;
  align-items: center;
  height: 45px;
}
.report_items .item .logo{
  max-height: 40px;
  max-width: 40px;
  margin-right: 10px;
  width: 100%;
}
.report_items .item .logo_link{
  min-width: 40px;
}
.report_items .item .faza{
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: white;
  font-size: 0.6rem;
  font-weight: bold;
  padding: 0px 3px;
  border-radius: 3px;
  border: 1px gray solid;
  line-height: 0.76rem;
  left: 7px;
}
.report_items .item .faza.faza_0{

  border: 1px #a2da7a solid;
  color: #a2da7a;
}
.report_items .item .faza.faza_gray{

  border: 1px #000000 solid;
  color: #000000;
}
.report_items .item .faza.faza_1{

  border: 1px #ff8853 solid;
  color: #ff8853;
}
.report_items .item .info{
  display: flex;
  flex-direction: column;
  /*width: calc(100% - 46px);*/
}
.report_items .item .info .name{
  color: var(--un-text-color);
  /*max-width: 120px;*/
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}
.report_items .item .info .ttl{
  /* overflow: hidden; */
  text-overflow: ellipsis;
  text-transform: capitalize;
  color: gray;
  white-space: nowrap;
}
.report_items .item .info .ttl .lnk{
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  color: gray;
  white-space: nowrap;
}
.report_items .item .info .ttl .bn{
  background-color: orange;
  align-items: center;
  justify-content: center;
  padding: 3px;
  display: inline-block;
  border-radius: 3px;
  color: black;
  font-size: 0.9rem;
  margin-left: 4px;
  cursor: pointer;
  margin-top: -2px;
}
.report_items .item .info .ttl .bn{
  background-color: #ffaf1d;
  color: black;
}
.report_items .item .info .ttl .cnt{
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 2px;
  padding: 0px 3px;
}
.report_items .item.partner{
  background-color: #fff8d5;
  padding: 5px;
  border-radius: 3px;
}
.report_items .item .action{
  margin-left: 10px;
}
.report_items .item .action a{
  color: var(--un-text-color);
}
.report_items .ic{
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.report_items.report_seeds .ic{
  width: 40px;
  height: 40;

}


.report_items .btn_less{
  position: absolute;
  right: 10px;
  font-size: 1.5rem;
  color: gray;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color:  var(--un-background-color-gray-dark);
  text-align: center;
  top: 5px;
}
.report_items.less .btn_less{
  display: inline-block;
}
.report_items.active .btn_less{

}
.report_items .btn_less .down{
  display: inline-block;
  margin: 0px;
}
.report_items.active .btn_less .down{
  display: none;
}
.report_items .btn_less .up{
  display: none;
  margin: 0px;
}
.report_items.active .btn_less .up{
  display: inline-block;
}
</style>